import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import Helmet from 'react-helmet'
import { Tags } from '@tryghost/helpers-gatsby'
import {
	FacebookShareButton,	
	LinkedinShareButton,
	TwitterShareButton,		
} from 'react-share'

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faFacebookF, faTwitter, faLinkedinIn} from '@fortawesome/free-brands-svg-icons'

import { Layout } from '../components/common'
import { MetaData } from '../components/common/meta'

/**
* Single post view (/:slug)
*
* This file renders a single post and loads all the content.
*
*/
const Post = ({ data, location }) => {
    const post = data.ghostPost

    return (
        <>
            <MetaData
                data={data}
                location={location}
                type="article"
            />
            <Helmet>
                <style type="text/css">{`${post.codeinjection_styles}`}</style>
            </Helmet>

            <Layout>
                <div className="container">

                    <article className="fullpost">

                        { post.feature_image ?                            
                            <img src={ post.feature_image } className="fullpost__feature-image" alt={ post.title } />
                            : null 
                        }

                        <div className="fullpost__head">
                            <div className="fullpost__infoline">
                                <div className="topic"><Tags post={post} visibility="public" autolink={false} /></div>
                                <div className="date">on {post.published_at_pretty}</div>
                            </div>
                            <div className="fullpost__title-wrapper">
                                <h1 className="blog-post__title">{post.title}</h1>
                            </div>                        
                        </div>
                        
                        <div className="fullpost__body">                      
                            {/* The main post content */ }
                            <section
                                className="content-body load-external-scripts"
                                dangerouslySetInnerHTML={{ __html: post.html }}
                            />
                        </div>

                        <div className="fullpost__footer">

                            <div className="autor-box">
                                <div className="autor-box__photo-wrapper">
                                    <img className="autor-box__photo" src={post.primary_author.profile_image} alt={post.primary_author.name} />
                                </div>
                                <div className="autor-box__info">
                                    <p className="autor-box__name">{post.primary_author.name}</p>
                                    <p className="autor-box__bio">{post.primary_author.bio}</p>
                                </div>
                            </div>

                        </div>

                    </article>


                    <div className="article-share">
                            <strong className="article-share__text">
                            share article
                            </strong>

                                    <ul className="article-share__list">
                                        <li className="article-share__list-item">
                                            <FacebookShareButton className="article-share__link-item" url={`https://blog.drive2.city/${post.slug}/`} >                                        
                                                <FontAwesomeIcon icon={faFacebookF} />
                                            </FacebookShareButton>
                                        </li>
                                        <li className="article-share__list-item">
                                            <TwitterShareButton className="article-share__link-item" url={`https://blog.drive2.city/${post.slug}/`} title={post.title} via={`TomNTY`} hashtags={post.tags} >
                                                    <FontAwesomeIcon icon={faTwitter} />
                                            </TwitterShareButton>
                                        </li>
                                        <li className="article-share__list-item">
                                            <LinkedinShareButton className="article-share__link-item" url={`https://blog.drive2.city/${post.slug}/`} title={post.title} >                                        
                                                    <FontAwesomeIcon icon={faLinkedinIn} />                                        
                                            </LinkedinShareButton>
                                        </li>
                                    </ul>
                    </div>


                </div>
            </Layout>
        </>
    )
}

Post.propTypes = {
    data: PropTypes.shape({
        ghostPost: PropTypes.shape({
            codeinjection_styles: PropTypes.object,
            title: PropTypes.string.isRequired,
            html: PropTypes.string.isRequired,
            feature_image: PropTypes.string,
        }).isRequired,
    }).isRequired,
    location: PropTypes.object.isRequired,
}

export default Post

export const postQuery = graphql`
    query($slug: String!) {
        ghostPost(slug: { eq: $slug }) {
            ...GhostPostFields
        }
    }
`
